<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-01-25 22:26:11
 * @LastEditors: hutian
 * @LastEditTime: 2021-01-28 15:58:24
-->
<template>
  <div class="cargo-detail">
    <div>
      <div style="text-algin: center">
        <ZoomMedia style="width: 100%;" :value="pro.imgUrl"></ZoomMedia>
      </div>
      <div class="table">
        <span class="table-left">商品名称：</span><span class="table-right">{{pro.name}}</span>
      </div>
      <div class="table">
        <span class="table-left">经销商：</span><span class="table-right">{{pro.customerName}}</span>
      </div>
      <div class="table">
        <span class="table-left">商品货号：</span><span class="table-right">{{pro.goodsCode}}</span>
      </div>
      <div class="table">
        <span class="table-left">发货时间：</span><span class="table-right">{{pro.shipTime}}</span>
      </div>
      <div class="table">
        <span class="table-left">生产厂商：</span><span class="table-right">珠海华粤</span>
      </div>
      <div class="table">
        <span class="table-left">商品是否合格：</span><span class="table-right">合格</span>
      </div>
      <div class="btn">
        <a-button type="primary" @click="searchAgain()">重新查询</a-button>
      </div>
    </div>
  </div>
</template>

<script>

import {queryInfo} from '@/views/wap/api/WapApi';

export default {

  data() {
    return {
       pro:{}
    }
  },

  created() {
    this.getParams();


  },

  methods:{
    getParams(){
      const _this = this
      // 取到路由带过来的参数
      const id = this.$route.params.id;
      queryInfo ({id:id}
      ).then(res => {
        console.log(res)
        if(res.code==200){
          _this.pro = res.body;
          console.log(res);
        }
      }).finally(() =>{

      })
    },

    searchAgain() {
      this.$router.back();
    }
  }


}
</script>

<style scoped>
  .cargo-detail .ant-form-item {
    margin-bottom: 0;
  }
  .cargo-detail .table {
    width: 100%;
    height: 30px;
    line-height: 30px;
    background: #F0F0F0;
    margin-top: 3px;
    border-top: 1px solid #E8E8E8;
    border-bottom: 1px solid #E8E8E8;
    padding: 0 1rem;
  }
  .cargo-detail .table .table-left {
    display: inline-block;
    width: 6.3rem;
    border-right: 1px solid #E8E8E8;
  }
  .cargo-detail .table .table-right {
    padding-left: .5rem;
  }
  .cargo-detail .btn {
    text-align: right;
    margin-top: 1rem;
  }
</style>
